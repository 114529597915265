import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import styled, { css } from "styled-components"
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js"

import { BREAKPOINTS, COLORS } from "src/constants"
import { StepTitle, Button } from "src/components/shop"
import LockIcon from "src/svg/Lock"
import { useCart } from "src/components/cart"
import functions, { handleError } from "src/helpers/functions"
import { IOrder } from "src/types"
import { getFullName, formatMoney } from "src/helpers/text"

const CardRow = styled.div`
  margin: 15px 0;
  > div {
    margin: 15px 0;
  }
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    display: flex;
    margin-right: -20px;
    > div {
      margin: 0 20px 0 0;
    }
  }
`
const cardCss = css`
  border: 1px solid ${COLORS.dark};
  padding: 5px 8px;
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    width: 100%;
  }
`
const CardNumber = styled(CardNumberElement)`
  ${cardCss}
  width: 400px;
`
const CardExpiry = styled(CardExpiryElement)`
  ${cardCss}
  width: 220px;
`
const CardCvc = styled(CardCvcElement)`
  ${cardCss}
  width: 160px;
`
const CARD_STYLE = {
  base: { fontFamily: '"Courier New", Courier, monospace', fontSize: "16px" },
  invalid: { color: COLORS.main },
}

const Note = styled.div`
  a {
    text-decoration: underline;
  }
`

interface IIntent {
  token: string
}

const Payment = () => {
  const { billing, bookings, presents, coupon, getTotal } = useCart()
  const stripe = useStripe()
  const elements = useElements()
  const [intent, setIntent] = useState<IIntent>()
  const [paying, setPaying] = useState(false)
  const total = getTotal()

  useEffect(() => {
    const payload: IOrder = {
      type: "stripe",
      total,
      coupon,
      billing,
      bookings,
      presents,
    }

    functions
      .post("intent", payload)
      .then(setIntent)
      .catch((error) => {
        handleError(error)
        navigate("/panier/")
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePay = () => {
    setPaying(true)

    stripe
      .confirmCardPayment(intent.token, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: getFullName(billing),
            email: billing.email,
          },
        },
      })
      .then(({ error, paymentIntent }) => {
        if (error) {
          alert(error.message)
          setPaying(false)
          return
        }
        if (paymentIntent.status !== "succeeded") {
          // TODO: report this
          alert("Paiement marqué comme " + paymentIntent.status)
        }
        navigate("/confirmation/", { replace: true, state: { total } })
      })
  }

  const loading = !stripe || !elements || !intent || paying

  return (
    <>
      <StepTitle>Paiement</StepTitle>
      <CardRow>
        <CardNumber
          options={{
            style: CARD_STYLE,
            placeholder: "Numéro de carte",
          }}
        />
      </CardRow>
      <CardRow>
        <CardExpiry
          options={{
            style: CARD_STYLE,
            placeholder: "Expiration (MM/AA)",
          }}
        />
        <CardCvc
          options={{
            style: CARD_STYLE,
            placeholder: "Code CVC",
          }}
        />
      </CardRow>
      <Button type="submit" disabled={loading} onClick={handlePay}>
        <LockIcon /> {loading ? "Veuillez patienter…" : `Payer ${formatMoney(total)}`}
      </Button>
      <Note>
        Formulaire sécurisé par{" "}
        <a href="https://stripe.com/fr" target="_blank" rel="noopener">
          Stripe
        </a>
      </Note>
    </>
  )
}

export default Payment

import React, { useState } from "react"
import { navigate } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import { GatsbyPage, IOrder } from "src/types"
import Layout from "src/layout"
import { Section } from "src/components/styled"
import { Content, Breadcrumbs, StepTitle, Button, Article, Separator, Row, ShopTitle } from "src/components/shop"
import { useCart, getKey } from "src/components/cart"
import { Label } from "src/components/Form"
import styled from "styled-components"
import { COLORS } from "src/constants"
import Payment from "src/components/Payment"
import functions, { handleError } from "src/helpers/functions"
import { getFullName, formatMoney } from "src/helpers/text"

const stripe = loadStripe(process.env.GATSBY_STRIPE, { apiVersion: "2020-03-02" })

const CouponRow = styled(Row)`
  color: ${COLORS.main};
  font-weight: normal;
`
const Free = styled.div`
  margin: 20px 0 10px;
`

const PaymentPage: GatsbyPage = () => {
  const { empty, billing, bookings, presents, coupon, getDiscount, getTotal } = useCart()
  const [paying, setPaying] = useState(false)

  const total = getTotal()

  const handleFree = () => {
    const payload: IOrder = {
      type: "free",
      total,
      coupon,
      billing,
      bookings,
      presents,
    }

    setPaying(true)

    functions
      .post("free", payload)
      .then(() => {
        setPaying(false)
        navigate("/confirmation/", { replace: true, state: { total } })
      })
      .catch((error) => {
        handleError(error)
        navigate("/panier/")
      })
  }

  return (
    <Layout title="Paiement" noindex>
      <ShopTitle />
      <Section>
        {empty ? (
          <Content>
            <p>Votre panier est vide</p>
          </Content>
        ) : (
          <Content>
            <Breadcrumbs active="paiement" />
            <StepTitle>Récapitulatif des achats</StepTitle>
            {bookings?.map((article) => (
              <Article key={getKey(article)} article={article} />
            ))}
            {presents?.map((article) => (
              <Article key={getKey(article)} article={article} />
            ))}
            <Separator />
            {coupon && (
              <CouponRow>
                <div>Code cadeau ou réduction</div>
                <div>{getDiscount()}</div>
              </CouponRow>
            )}
            <Row>
              <div>Total à payer</div>
              <div>{formatMoney(total)}</div>
            </Row>
            <StepTitle>Informations de facturation</StepTitle>
            <Label>Nom : {getFullName(billing)}</Label>
            <Label>E-mail : {billing.email}</Label>
            {total > 0 ? (
              <Elements stripe={stripe}>
                <Payment />
              </Elements>
            ) : (
              <>
                <Free>Vous n’avez rien à payer.</Free>
                <Button onClick={handleFree} disabled={paying}>
                  {paying ? "Veuillez patienter..." : "Valider votre commande"}
                </Button>
              </>
            )}
          </Content>
        )}
      </Section>
    </Layout>
  )
}

export default PaymentPage
